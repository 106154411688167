import React, { useState, useEffect, useRef } from 'react';
import HubManagerService from 'core/service/HubManagerService';
import {
  CustomDatePicker,
  DropdownComponent,
  PaginationComponent,
  SearchComponent,
  TableHeaderComponent,
  dateStartsWithDay,
  formatDate,
  getStatusBackgroundColor,
  toPascalWord
} from 'components/commonComponent/commonComponents';
import { Card, Dropdown, Table } from 'react-bootstrap';
import './../../../assets/scss/user.scss';
import Flex from 'components/common/Flex';
import Loader from 'core/loader/loader';
import { handleErrorResponse } from 'core/tokenInterceptor/AxiosInstance';
import SubtleBadge from 'components/common/SubtleBadge';
import CardDropdown from 'components/common/CardDropdown';
import { useNavigate } from 'react-router-dom';
import DateService from 'core/service/dateService';

const HubVehiclesRideHistory = () => {
  const navigate = useNavigate();
  const dateService = new DateService();
  const [showNoRideHistoryDiv, setShowNoRideHistoryDiv] = useState(true);
  const hubManagerService = new HubManagerService();
  const [selectedStatus, setSelectedStatus] = useState('All');
  const [selectedPayment, setSelectedPayment] = useState('All');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const columnNames = [
    'Ride ID',
    'Driver ID',
    'Vehicle Number',
    'Date',
    'Pickup Location',
    'Drop Location',
    'Ride Status',
    'Amount',
    'Payment Method',
    'Invoice',
    'Action'
  ];
  const [selectedDate, setSelectedDate] = useState(() =>
    dateService.getSelectedDate()
  );
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (dateService.isFlagSet('fromInvoice')) {
      setSelectedDate(dateService.getSelectedDate());
      dateService.clearPersistedFlag('fromInvoice');
    } else {
      setSelectedDate(new Date());
    }
  }, []);

  useEffect(() => {
    dateService.setSelectedDate(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    const fetchRideHistory = () => {
      if (searchTerm.trim() === '') {
        fetchHubVehiclesRideHistory();
      } else {
        searchHubVehiclesRideHistory();
      }
    };

    if (selectedDate && selectedStatus && selectedPayment) {
      fetchRideHistory();
    }
  }, [currentPage, selectedDate, selectedStatus, selectedPayment, searchTerm]);

  const fetchHubVehiclesRideHistory = () => {
    const rideStatus =
      selectedStatus === 'All'
        ? ['COMPLETED', 'CANCELLED', 'ASSIGNED', 'ARRIVED', 'ONBOARD'].join(',')
        : selectedStatus;

    const paymentMethod =
      selectedPayment === 'All' ? ['CASH', 'UPI'].join(',') : selectedPayment;

    setShowNoRideHistoryDiv(true);
    setLoading(true);

    hubManagerService
      .fetchHubVehiclesRideHistory(
        ((currentPage - 1) / ENTRIES_PER_PAGE) * 10,
        ENTRIES_PER_PAGE,
        rideStatus.toUpperCase(),
        paymentMethod.toUpperCase(),
        dateStartsWithDay(selectedDate)
      )
      .then(response => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          const responseBody = response.data.responseBody;
          setData(responseBody);
        }
      })
      .catch(err => {
        setLoading(false);
        setShowNoRideHistoryDiv(false);
        setData(null);
        handleErrorResponse('fetchHubVehiclesRideHistory', err.response);
      });
  };

  const searchHubVehiclesRideHistory = () => {
    setShowNoRideHistoryDiv(true);
    setLoading(true);

    const searchType = getSearchType(searchTerm);
    hubManagerService
      .searchHubRideHistory(
        ((currentPage - 1) / ENTRIES_PER_PAGE) * 10,
        ENTRIES_PER_PAGE,
        searchTerm,
        searchType
      )
      .then(response => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          const responseBody = response.data.responseBody;
          setData(responseBody);
        }
      })
      .catch(err => {
        setLoading(false);
        setShowNoRideHistoryDiv(false);
        setData(null);
        handleErrorResponse('searchHubRideHistory', err.response);
      });
  };

  const getSearchType = term => {
    const lowerCaseTerm = term.toLowerCase();

    if (lowerCaseTerm.startsWith('rushdr') || lowerCaseTerm === 'rushd') {
      return 'duid';
    }
    if (
      lowerCaseTerm.startsWith('rush') ||
      lowerCaseTerm === 'rus' ||
      lowerCaseTerm === 'ru' ||
      lowerCaseTerm === 'r'
    ) {
      return 'ruid';
    }
    return 'cabNumber';
  };

  const ENTRIES_PER_PAGE = 10;

  const TableRow = ({ ride }) => {
    const bg = getStatusBackgroundColor(ride.rideStatus);
    const handleEditClick = (event, ride) => {
      event.preventDefault();
      localStorage.setItem('fromInvoice', 'true');
      navigate(`/manage-rides/invoice?ruid=${encodeURIComponent(ride.ruid)}`, {
        state: ride
      });
    };

    return (
      <tr className="align-middle">
        <td className="col-fixed-width color-gray-fs-13-fw-400">{ride.ruid}</td>
        <td className="col-fixed-width color-gray-fs-13-fw-400">
          {ride.driverDetailsResponseDTO.duid}
        </td>
        <td className="col-fixed-width color-gray-fs-13-fw-400">
          {ride.cabDetailsDTO.cabNumber}
        </td>
        <td className="col-fixed-width color-gray-fs-13-fw-400">
          {formatDate(ride.genericDetails.createdTime)}
        </td>
        <td className="col-fixed-width color-gray-fs-13-fw-400">
          {ride.pickupDetails.pickUpAddress.address}
        </td>
        <td className="col-fixed-width color-gray-fs-13-fw-400">
          {ride.dropDetails.dropAddress.address}
        </td>
        <td className="col-fixed-width ">
          <SubtleBadge pill bg={bg}>
            {toPascalWord(ride.rideStatus)}
          </SubtleBadge>
        </td>
        <td className="col-fixed-width color-gray-fs-13-fw-400">
          {ride.rideStatus === 'CANCELLED'
            ? '₹ 0'
            : `₹ ${ride.fareAfterDiscount || ride.fare}`}
        </td>

        <td className="col-fixed-width color-gray-fs-13-fw-400">
          {ride.paymentMethod}
        </td>
        <td>
          {ride.rideStatus === 'CANCELLED' ? (
            <span className="disable-view ">View</span>
          ) : ride.rideStatus === 'COMPLETED' && ride.paymentReceived ? (
            <a href="#" onClick={event => handleEditClick(event, ride)}>
              View
            </a>
          ) : (
            <span className="blue-text">_______</span>
          )}
        </td>
        <td>
          <CardDropdown variant="falcon-default">
            <div className="py-2">
              <Dropdown.Item href="#!">View</Dropdown.Item>
            </div>
          </CardDropdown>
        </td>
      </tr>
    );
  };

  const renderHubRideHistory = () => {
    if (!data) {
      return null;
    }

    let rideDetailsList = data.searchResult;

    if (!Array.isArray(rideDetailsList)) {
      return null;
    }

    return rideDetailsList.map((entry, index) => (
      <TableRow ride={entry} key={index} />
    ));
  };

  const handleRideStatus = selectedStatus => {
    setSelectedStatus(selectedStatus === 'all' ? '' : selectedStatus);
    setCurrentPage(1);
  };

  const handlePaymentMethod = selectedPayment => {
    setSelectedPayment(selectedPayment === 'all' ? '' : selectedPayment);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(data?.totalItems / ENTRIES_PER_PAGE) || 1;
  const isNextDisabled = currentPage >= totalPages;
  const isPrevDisabled = currentPage === 1;

  const nextPage = () => {
    if (!isNextDisabled) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (!isPrevDisabled) {
      setCurrentPage(currentPage - 1);
    }
  };

  const rideStatusOptions = [
    { id: 1, value: 'All', label: 'All' },
    { id: 2, value: 'Completed', label: 'Completed' },
    { id: 3, value: 'Cancelled', label: 'Cancelled' },
    { id: 4, value: 'Assigned', label: 'Assigned' },
    { id: 5, value: 'Arrived', label: 'Arrived' },
    { id: 6, value: 'OnBoard', label: 'OnBoard' }
  ];
  const paymentOptions = [
    { id: 1, value: 'All', label: 'All' },
    { id: 2, value: 'CASH', label: 'CASH' },
    { id: 3, value: 'UPI', label: 'UPI' }
  ];

  return (
    <>
      <Card>
        <div className="p-3">
          <Flex className="justify-content-between">
            <SearchComponent
              searchTerm={searchTerm}
              onSearchChange={setSearchTerm}
            />
            <Flex className="justify-content-end">
              <div className="me-3">
                <DropdownComponent
                  title="Ride Status"
                  selected={selectedStatus}
                  options={rideStatusOptions}
                  onSelect={handleRideStatus}
                />
              </div>
              <DropdownComponent
                title="Payment Method"
                selected={selectedPayment}
                options={paymentOptions}
                onSelect={handlePaymentMethod}
              />
            </Flex>
          </Flex>
          <Flex className="d-flex justify-content-end mt-3">
            <div>
              <CustomDatePicker
                selectedDate={selectedDate}
                onChange={date => setSelectedDate(date)}
              />
            </div>
          </Flex>
        </div>
      </Card>
      {showNoRideHistoryDiv ? (
        <Card>
          <Card.Body className="p-0">
            <div className="table-responsive">
              <Table className="table table-striped table-hover">
                <thead className="header-padding">
                  <TableHeaderComponent columnNames={columnNames} />
                </thead>
                <tbody>
                  {loading && (
                    <tr>
                      <td colSpan={columnNames.length} className="text-center">
                        <Loader loading={loading} />
                      </td>
                    </tr>
                  )}
                  {!loading && renderHubRideHistory()}
                </tbody>
              </Table>
            </div>
            <PaginationComponent
              prevPage={prevPage}
              nextPage={nextPage}
              isNextDisabled={isNextDisabled}
              isPrevDisabled={isPrevDisabled}
            />
          </Card.Body>
        </Card>
      ) : (
        <div>
          <div className="d-flex justify-content-center">
            <img
              src={require('./../../../assets/img/no-ride-history.png')}
              alt="No RideHistory"
            />
          </div>
          <p className="no-content-text text-center">No Ride History Found</p>
          <p className={`filter-message text-center`}>
            Try changing the filters
          </p>
        </div>
      )}
    </>
  );
};

export default HubVehiclesRideHistory;
