import React, { useState, useEffect, useRef } from 'react';
import { Card } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import Divider from 'components/common/Divider';
import Loader from 'core/loader/loader';
import { toast } from 'react-toastify';
import NotificationService from 'core/service/NotificationService';
import formatTimestamp from 'core/timeStamp/timestampUtils';
import InfiniteScroll from 'react-infinite-scroll-component';
import styles from './NotificationComponent.module.scss';
import DialogBoxComponent from 'components/dialogBox/DialogBoxComponent';
import DataTransferService from 'core/service/dataTransferService';

const NotificationComponent = () => {
  const dataTransferService = new DataTransferService();
  const notificationService = new NotificationService();
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showNoNotificationDiv, setShowNoNotificationDiv] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [selectedNotificationData, setSelectedNotificationData] =
    useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchNotificationsList();
    } else if (!loading && page !== 0) {
      fetchNotificationsList();
    }
  }, [page]);

  const handleNotificationAction = notification => {
    setNotifications(prevNotifications =>
      prevNotifications.filter(item => item !== notification)
    );
  };

  const fetchNotificationsList = async () => {
    try {
      setLoading(true);
      const response = await notificationService.fetchHubRequestNotifications(
        page,
        10
      );
      if (response.data.responseCode === 200) {
        const responseBody = response.data.responseBody;
        const newNotifications = responseBody.requestCabDTOList || [];
        setNotifications(prevNotifications => [
          ...prevNotifications,
          ...newNotifications
        ]);
        setHasMore(responseBody.totalPages > page + 1);
      }
    } catch (error) {
      if (error.response.data.responseCode !== 404) {
        toast.error(
          error.response
            ? `${error.response.data.responseMessage}`
            : 'Failed to connect to the server. Please try again later.'
        );
      } else {
        setLoading(false);
        setShowNoNotificationDiv(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const renderNotificationItem = (notification, index) => {
    const notificationText =
      notification.notificationType === 'CHANGE_CAB_REQUEST'
        ? 'has requested for Change Vehicle'
        : 'has requested for checkout';

    const handleClick = () => {
      setSelectedNotificationData({ notification });
      setShowDialog(true);
    };

    return (
      <div
        key={`notification_${index}`}
        className={styles['notification-item']}
        onClick={handleClick}
      >
        <div className="d-flex align-items-center p-3 pointer">
          <div className="px-3">
            <Avatar
              src={
                notification.driverDetails.profileImage == ''
                  ? dataTransferService.setdefualtProfileImage()
                  : dataTransferService.getBloburl() +
                    notification.driverDetails.profileImage
              }
            />
          </div>
          <div className="ms-1">
            <div className="d-flex">
              <p className="fs-16 m-0">
                <b className={styles['name']}>
                  {notification.driverDetails.firstName}{' '}
                </b>
                <span className={styles['notification-text']}>
                  ({notification.driverDetails.duid}) {notificationText}
                </span>
              </p>
            </div>
            <p className={`${styles['notification-text']} m-0`}>
              {formatTimestamp(notification.createdAt)}
            </p>
          </div>
        </div>
        <Divider />
      </div>
    );
  };

  return (
    <div className="px-3">
      <h5 className="frame-bottom">Notifications</h5>
      {notifications.length > 0 && (
        <div className="mt-32">
          <Card className={styles['card-shadow']}>
            <div className={styles['card-heading']}>
              <div className={styles['header-content']}>Your Notifications</div>
            </div>
            <Divider />
            <InfiniteScroll
              dataLength={notifications.length}
              next={() => setPage(page + 1)}
              hasMore={hasMore}
              loader={<Loader loading={loading} />}
            >
              {notifications.map((notification, index) =>
                renderNotificationItem(notification, index)
              )}
            </InfiniteScroll>
          </Card>
        </div>
      )}
      {showNoNotificationDiv && (
        <div>
          <div className="d-flex justify-content-center">
            <img
              src={require('./../../assets/img/no-notification.png')}
              alt="No notifications"
            />
          </div>
          <p className={`no-content-message d-flex justify-content-center`}>
            No New Notifications
          </p>
        </div>
      )}
      {showDialog && (
        <DialogBoxComponent
          selectedNotificationData={selectedNotificationData}
          onHide={notification => {
            setShowDialog(false);
            handleNotificationAction(notification);
          }}
        />
      )}
    </div>
  );
};

export default NotificationComponent;
