import React, { useState, useEffect } from 'react';
import { Card, Table, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Background from 'components/common/Background';
import SubtleBadge from 'components/common/SubtleBadge';
import BackgroundIcon from 'assets/img/icons/spot-illustrations/corner-2.png';
import WalletImage from 'assets/img/wallet.png';
import UPIPaymentImage from 'assets/img/UPI-payment.png';
import './InvoiceComponent.scss';
import InvoiceMapComponent from 'components/invoiceMap/InvoiceMapComponent';
import HubManagerService from 'core/service/HubManagerService';
import { toast } from 'react-toastify';
import Header from 'components/common/Header';
import { toPascalWord } from 'components/commonComponent/commonComponents';

const InvoiceComponent = () => {
  const location = useLocation();
  const { state } = location;
  const hubManagerService = new HubManagerService();
  const [pathCoordinates, setPathCoordinates] = useState([]);
  const [startCoords, setStartCoords] = useState(null);
  const [endCoords, setEndCoords] = useState(null);
  const [showRidePath, setRidePath] = useState(false);
  const [pathType, setPathType] = useState('');

  useEffect(() => {
    if (state.rideStatus === 'COMPLETED') {
      fetchCoordinates(state.ruid);
    } else {
      const pickupCoordinates = {
        lat: state.pickupDetails.pickUpAddress.latitudeAddress,
        lng: state.pickupDetails.pickUpAddress.longitudeAddress
      };
      const dropCoordinates = {
        lat: state.dropDetails.dropAddress.latitudeAddress,
        lng: state.dropDetails.dropAddress.longitudeAddress
      };
      setStartCoords(pickupCoordinates);
      setEndCoords(dropCoordinates);
      setPathCoordinates([pickupCoordinates, dropCoordinates]);
    }

    const pathname = location.pathname;

    if (pathname.includes('drivers') && !pathname.includes('active-drivers')) {
      setPathType('Drivers');
    } else if (pathname.includes('manage-rides')) {
      setPathType('Manage Rides');
    } else {
      setPathType('Active Drivers');
    }
  }, [state, location.pathname]);

  const formatDate = dateString => {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };
    return date.toLocaleString(undefined, options);
  };

  const fetchCoordinates = rideData => {
    hubManagerService
      .fetchRideCoordinatesList(rideData)
      .then(response => {
        if (response && response.data && response.data.responseCode === 200) {
          const coordinatesList = response.data.responseBody.coordinates_list;
          if (coordinatesList && coordinatesList.length > 0) {
            const formattedCoordinates = coordinatesList.map(coord => ({
              lat: coord.lat,
              lng: coord.lng
            }));
            setPathCoordinates(formattedCoordinates);
            setStartCoords(formattedCoordinates[0]);
            setEndCoords(formattedCoordinates[formattedCoordinates.length - 1]);
            setRidePath(true);
          }
        }
      })

      .catch(error => {
        toast.error(`${error.response.data.responseMessage}`);
      });
  };

  return (
    <div className="py-3">
      <Header
        breadcrumb={`Manage Drivers/${
          pathType === 'Manage Rides'
            ? 'Dashboard/Manage Rides/Invoice'
            : `Manage Drivers/ ${pathType}/Ridehistory/Invoice`
        }`}
        title="Invoice"
      />

      <div className="row columns-height pt-2">
        <div className="col-6">
          <Card className="mb-3">
            <Background image={BackgroundIcon} className="bg-card" />
            <Card.Body className="position-relative">
              <div>
                <h5>Ride ID: #{state.ruid || ''}</h5>
                <p className="fs-10 mt-1">
                  {formatDate(
                    state.genericDetails?.createdTime || state?.created_time
                  )}
                </p>
                <div>
                  <strong className="me-2">Status: </strong>
                  {state.rideStatus === 'COMPLETED' ? (
                    <SubtleBadge pill bg="success" className="fs-11">
                      {toPascalWord(state.rideStatus || '')}
                      <FontAwesomeIcon
                        icon="check"
                        className="ms-1"
                        transform="shrink-2"
                      />
                    </SubtleBadge>
                  ) : (
                    <SubtleBadge pill bg="danger" className="fs-11">
                      {state.rideStatus || ''}
                      <FontAwesomeIcon
                        icon="check"
                        className="ms-1"
                        transform="shrink-2"
                      />
                    </SubtleBadge>
                  )}
                </div>

                <div className="pt-3">
                  <p className="mb-2 body-text-semi-bold">
                    Username:{' '}
                    <span className="value">
                      {state.userDetailsResponseDTO?.firstName ||
                        state?.firstName ||
                        ''}
                    </span>
                  </p>
                  <p className="mb-2 body-text-semi-bold">
                    Vehicle Number:{' '}
                    <span className="value">
                      {state.cabDetailsDTO?.cabNumber || state?.cabNumber || ''}
                    </span>
                  </p>
                  <p className="mb-2 body-text-semi-bold">
                    Ride Duration:{' '}
                    <span className="value">
                      {state.rideDuration || ''} mins
                    </span>
                  </p>
                  <p className="mb-2 body-text-semi-bold">
                    Ride Distance:{' '}
                    <span className="value">
                      {state.rideDistance || ''} Kms
                    </span>
                  </p>
                </div>
              </div>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body className="p-0 pb-7">
              <div className="table-responsive fs-10">
                <Table striped className="border-bottom">
                  <thead className="bg-200">
                    <tr>
                      <th className="text-900 header-text border-0">
                        Description
                      </th>
                      <th className="text-900 header-text border-0 text-end">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-900 input-field">
                        <strong>Ride Fare</strong>
                        <div className="body-text pt-1">(Including Taxes)</div>
                      </td>
                      <td className="text-end">
                        ₹{(state.fare || 0).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-900 input-field">
                        <strong>Coupon Code Offer</strong>
                        <div className="body-text pt-1">{state.offerCode}</div>
                      </td>
                      <td className="text-end">
                        -₹{(state.couponSaving || 0).toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Row className="g-0 justify-content-end">
                  <Col xs="auto">
                    <Table borderless size="sm" className="fs-10 text-end">
                      <tbody>
                        <tr>
                          <th className="text-900">
                            <strong>Total (round off):</strong>
                          </th>
                          <td className="body-text-semi-bold text-end">
                            ₹
                            {(state.fareAfterDiscount || state.fare).toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-6">
          <Card>
            <Card.Body>
              <div className="pb-4">
                <InvoiceMapComponent
                  pathCoordinates={pathCoordinates}
                  startCoords={startCoords}
                  endCoords={endCoords}
                  showPolyline={showRidePath}
                />
              </div>
              <p className="location-field">Pickup Location</p>
              <p className="location-text pb-2">
                {state?.pickupDetails?.pickUpAddress?.address ||
                  state?.pickupAddressText ||
                  ''}
              </p>
              <p className="location-field">Drop Location</p>
              <p className="location-text pb-2">
                {state?.dropDetails?.dropAddress?.address ||
                  state?.dropAddressText ||
                  ''}
              </p>
              <p className="location-field">Payment Method</p>
              <p>
                <img
                  src={WalletImage}
                  alt="wallet"
                  className="payment-image ms-2"
                />
                {state.paymentMethod === 'UPI' ? (
                  <img
                    src={UPIPaymentImage}
                    alt="UPI Payment"
                    className="payment-image ms-2"
                  />
                ) : (
                  <span className="location-text ms-3">
                    {state.paymentMethod || ''}
                  </span>
                )}
              </p>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default InvoiceComponent;
