import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HubManagerService from 'core/service/HubManagerService';
import SubtleBadge from 'components/common/SubtleBadge';
import { Card, Table, Button } from 'react-bootstrap';
import './../../../../assets/scss/user.scss';
import Flex from 'components/common/Flex';
import Loader from 'core/loader/loader';
import { useNavigate } from 'react-router-dom';
import {
  CustomDatePicker,
  formatDate
} from 'components/commonComponent/commonComponents';
import NoDriversImage from 'assets/img/drivers-not-found.png';
import { handleErrorResponse } from 'core/tokenInterceptor/AxiosInstance';
import Header from 'components/common/Header';
import DateService from 'core/service/dateService';

const ActiveDrivers = () => {
  const navigate = useNavigate();
  const hubManagerService = new HubManagerService();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [searchResults, setSearchResults] = useState([]);
  const dateService = new DateService();
  const columnNames = [
    'Driver Id',
    'Fullname',
    'CheckIn',
    'Vehicle',
    'CheckOut',
    'Cash Collected',
    'Total Payments'
  ];
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    const persistedDate = dateService.getSelectedDate();
    if (dateService.isFlagSet('fromRideHistory')) {
      setSelectedDate(persistedDate);
      dateService.clearPersistedFlag('fromRideHistory');
    } else {
      setSelectedDate(new Date());
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      const fetchDrivers = () => {
        if (searchTerm.trim() === '') {
          activeDrivers(currentPage);
        } else {
          searchActiveDrivers(searchTerm, currentPage);
        }
      };
      fetchDrivers();
    }
  }, [searchTerm, selectedDate, currentPage]);

  const activeDrivers = pageIndex => {
    const formattedDate = selectedDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    setLoading(true);
    hubManagerService
      .activeDrivers(pageIndex, 10, formattedDate)
      .then(response => {
        setLoading(false);
        if (response.data.responseCode === 200) {
          const responseBody = response.data.responseBody;
          setDrivers(responseBody.searchResult);
          setTotalPages(Math.ceil(responseBody.totalItems / 10));
        }
      })
      .catch(error => {
        setLoading(false);
        setDrivers([]);
        handleErrorResponse('activeDrivers', error.response);
      });
  };

  const searchActiveDrivers = (searchTerm, pageIndex) => {
    const formattedDate = selectedDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    setLoading(true);
    hubManagerService
      .searchActiveDrivers(searchTerm, pageIndex, 10, formattedDate)
      .then(response => {
        setLoading(false);
        if (response.data.responseCode === 200) {
          const responseBody = response.data.responseBody;
          setSearchResults(responseBody.searchResult);
          setTotalPages(Math.ceil(responseBody.totalItems / 10));
        }
      })
      .catch(error => {
        setLoading(false);
        setSearchResults([]);
        handleErrorResponse('searchActiveDrivers', error.response);
      });
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const isNextDisabled = currentPage >= totalPages - 1 || totalPages === 0;

  const renderTableHeaders = () => {
    return (
      <tr>
        {columnNames.map((columnName, index) => (
          <th key={index} className="col-fixed-width">
            {columnName}
          </th>
        ))}
      </tr>
    );
  };

  const handleEditClick = (event, data) => {
    event.preventDefault();
    const duid = data.duid || data.driverDetails.duid;
    localStorage.setItem('fromRideHistory', 'true');
    navigate(
      `/manage-drivers/active-drivers/ride-history?duid=${encodeURIComponent(
        duid
      )}&date=${encodeURIComponent(selectedDate.toISOString())}`
    );
  };

  const TableRow = ({ data }) => (
    <tr className="align-middle">
      <td className="col-fixed-width">
        <a href="#" onClick={event => handleEditClick(event, data)}>
          {data.duid || data.driverDetails.duid}
        </a>
      </td>
      <td className="col-fixed-width">
        {data.firstName || data.driverDetails.fullName}
      </td>
      <td className="col-fixed-width">{formatDate(data.checkIn)}</td>
      <td className="col-fixed-width">
        {data.cabDetailsDTO
          ? data.cabDetailsDTO.cabNumber
          : data.cabNumber || '--'}
      </td>
      <td className="col-fixed-width">{formatDate(data.checkOut) || '--'}</td>
      <td className="center">
        {data.handoverCash !== 0 ? (
          <SubtleBadge pill bg="success" className="me-2">
            {data.handoverCash}
          </SubtleBadge>
        ) : (
          <span>--</span>
        )}
      </td>
      <td className="center">
        {data.totalPayment !== 0 ? (
          <SubtleBadge pill bg="success" className="me-2">
            {data.totalPayment}
          </SubtleBadge>
        ) : (
          <span>--</span>
        )}
      </td>
    </tr>
  );

  const renderDrivers = () => {
    const driversToRender = searchTerm.trim() === '' ? drivers : searchResults;
    return driversToRender.map(driver => (
      <TableRow data={driver} key={driver.duid || driver.driverDetails.duid} />
    ));
  };

  return (
    <div className="py-3">
      <Header
        breadcrumb="Manage Drivers/Active Drivers"
        title="Active Drivers"
      />

      <Card>
        <div className="bottom-padding">
          <Flex className="justify-content-between flex-wrap">
            <div className="search-container justify-content-start">
              <FontAwesomeIcon icon="search" className="search-icon" />
              <input
                className="search-content"
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>
            <Flex className="justify-content-end">
              <div>
                <CustomDatePicker
                  selectedDate={selectedDate}
                  onChange={date => setSelectedDate(date)}
                />
              </div>
            </Flex>
          </Flex>
        </div>
      </Card>

      {loading ? (
        <Loader loading={loading} />
      ) : (
        <>
          {drivers.length === 0 && searchResults.length === 0 ? (
            <div className="text-center">
              <img
                className="w-50 h-25"
                src={NoDriversImage}
                alt="Empty state"
              />
              <p className="no-content-text">No drivers found</p>
            </div>
          ) : (
            <Card>
              <Table striped responsive hover>
                <thead className="header-padding">{renderTableHeaders()}</thead>
                <tbody>{renderDrivers()}</tbody>
              </Table>
              <div className="bottom-padding">
                <Flex className="justify-content-end">
                  <Button
                    size="sm"
                    onClick={handlePrevPage}
                    disabled={currentPage === 0}
                  >
                    Previous
                  </Button>
                  <Button
                    size="sm"
                    className="px-4 ms-2"
                    onClick={handleNextPage}
                    disabled={isNextDisabled}
                  >
                    Next
                  </Button>
                </Flex>
              </div>
            </Card>
          )}
        </>
      )}
    </div>
  );
};

export default ActiveDrivers;
